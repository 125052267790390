import React from "react"
import "./BlogHeader.css"

const BlogHeader = () => {
    return (
        <div className="blog-header">
            <h1 className="blog-header__title">Thinking Blog</h1>
            <h2 className="blog-header__subtitle">
                Useful tips & tricks for every serious software developer and
                devops engineers. Read about advanced web technologies of today
                like React, Sitecore, Coveo etc.
            </h2>
        </div>
    )
}

export default BlogHeader
