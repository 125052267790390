import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import PageHeader from "../components/PageHeader/PageHeader"
import BlogHeader from "../components/BlogHeader/BlogHeader"
import BlogSearch from "../components/BlogSearch/BlogSearch"
import BlogThumb from "../components/BlogThumb/BlogThumb"
import ReactPaginate from "react-paginate"
import "../pages/styles/blog.css"
import "../pages/styles/pagination.css"

const BlogPage = ({ location }) => {
    const MAX_POST_PER_PAGE = 6

    const data = useStaticQuery(graphql`
        query {
            allContentfulBlogPost(
                sort: { fields: datePublished, order: DESC }
            ) {
                totalCount
                edges {
                    node {
                        id
                        slug
                        title
                        image {
                            description
                            fluid {
                                aspectRatio
                                base64
                                sizes
                                src
                                srcSet
                                srcSetWebp
                                srcWebp
                                tracedSVG
                            }
                        }
                        category {
                            title
                        }
                        content {
                            childMarkdownRemark {
                                excerpt
                            }
                        }
                        datePublished(formatString: "DD. MMMM YYYY.")
                    }
                }
            }
            categoryASC: allContentfulBlogPost(
                sort: { fields: datePublished, order: ASC }
            ) {
                edges {
                    node {
                        id
                        slug
                        title
                        image {
                            description
                            fluid {
                                aspectRatio
                                base64
                                sizes
                                src
                                srcSet
                                srcSetWebp
                                srcWebp
                                tracedSVG
                            }
                        }
                        category {
                            title
                        }
                        content {
                            childMarkdownRemark {
                                excerpt
                            }
                        }
                        datePublished(formatString: "DD. MMMM YYYY.")
                    }
                }
            }
        }
    `)

    const [paginationData, setPaginationData] = useState({
        allPosts: [...data.allContentfulBlogPost.edges],
        posts: [],
        start: 0,
        end: MAX_POST_PER_PAGE,
        searchQuery: "",
    })

    const handlePageClick = data => {
        let selected = data.selected
        let postsPerPages = Math.ceil(selected * MAX_POST_PER_PAGE)

        setPaginationData(paginationData => ({
            ...paginationData,
            start: postsPerPages,
            end: postsPerPages + MAX_POST_PER_PAGE,
        }))
    }

    const onSearch = e => {
        let term = e.target.value
        handleSearch(term)
    }

    const handleSearch = term => {
        const searchedData = paginationData.allPosts.filter(post => {
            const { title } = post.node
            const { excerpt } = post.node.content.childMarkdownRemark

            return (
                title.toLowerCase().includes(term.toLowerCase()) ||
                excerpt.toLowerCase().includes(term.toLowerCase())
            )
        })

        setPaginationData(paginationData => ({
            ...paginationData,
            posts: searchedData,
            searchQuery: term,
        }))
    }

    const handleFilter = e => {
        let value = e.target.value
        let filteredData =
            value === "Show newest first"
                ? paginationData.allPosts
                : data.categoryASC.edges

        setPaginationData(paginationData => ({
            ...paginationData,
            posts: filteredData,
            searchQuery: "",
        }))
    }

    return (
        <Layout location={location}>
            <div className="Blog">
                <div className="Blog-container">
                    <PageHeader PageHeaderTitle={"Back to Homepage"} />
                    <BlogHeader />
                    <BlogSearch
                        {...paginationData}
                        onSearch={() => onSearch}
                        handleFilter={() => handleFilter}
                    />
                    <BlogThumb {...paginationData} />
                    <ReactPaginate
                        previousLabel={
                            <span className="Pagination-item-arrow">
                                &#171;
                            </span>
                        }
                        nextLabel={
                            <span className="Pagination-item-arrow">
                                &#187;
                            </span>
                        }
                        pageCount={
                            paginationData.posts.length
                                ? Math.ceil(
                                      paginationData.posts.length /
                                          MAX_POST_PER_PAGE
                                  )
                                : Math.ceil(
                                      paginationData.allPosts.length /
                                          MAX_POST_PER_PAGE
                                  )
                        }
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName="Pagination"
                        pageClassName="Pagination-item"
                        previousClassName="Pagination-item-nav"
                        nextClassName="Pagination-item-nav"
                        activeClassName="Pagination-item--active"
                        disabledClassName="Pagiantion-item-nav--disabled"
                    />
                </div>
            </div>
        </Layout>
    )
}

export default BlogPage
