import React from "react"
import search from "../../assets/icon/icon-search.svg"
import downArrow from "../../assets/icon/icon-arrow-down.svg"
import "./BlogSearch.css"

const BlogSearch = ({ searchQuery, onSearch, handleFilter }) => {
    return (
        <div className="blog-search">
            <div className="blog-search__wrap blog-search__wrap--input">
                <input
                    className="blog-search__input"
                    placeholder="Searching blog..."
                    onChange={onSearch()}
                    value={searchQuery}
                />
                <img
                    src={search}
                    className="blog-search__icon-image"
                    alt="search icon"
                />
            </div>
            <div className="blog-search__wrap blog-search__wrap--dropdown">
                <select
                    className="blog-search__dropdown"
                    onChange={handleFilter()}
                >
                    <option value="Show newest first">Show newest first</option>
                    <option value="Show oldest first">Show oldest first</option>
                </select>
                <img
                    src={downArrow}
                    className="blog-search__icon-image"
                    alt="down arrow icon"
                />
            </div>
        </div>
    )
}

export default BlogSearch
