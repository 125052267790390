import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import "./BlogThumb.css"

const BlogThumb = ({ allPosts, posts, start, end, searchQuery }) => {
    console.log(searchQuery)
    const postData =
        (posts.length && searchQuery !== "") ||
        (posts.length && searchQuery === "")
            ? posts
            : allPosts
    const noSearchResult = !posts.length && searchQuery !== ""

    return (
        <React.Fragment>
            {noSearchResult ? (
                <div className="BlogThumb-message">
                    <h3 className="BlogThumb-message-text">
                        Oops, we were unable to find a match based on your
                        search. <br />
                        Please feel free to try your search again.
                    </h3>
                </div>
            ) : (
                <div className="BlogThumb-wrap">
                    {postData
                        .filter((el, i) => i >= start && i < end)
                        .map((node, i) => (
                            <article
                                key={i + node.node.id}
                                className="BlogThumb"
                            >
                                <span className="BlogThumb-date">
                                    {node.node.datePublished}
                                </span>
                                <div className="BlogThumb-image">
                                    <Img fluid={node.node.image.fluid} />
                                </div>
                                <h3 className="BlogThumb-title">
                                    {node.node.title}
                                </h3>
                                <div
                                    className="BlogThumb-text"
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            node.node.content
                                                .childMarkdownRemark.excerpt,
                                    }}
                                />
                                <Link
                                    className="BlogThumb-button"
                                    to={node.node.slug}
                                >
                                    Read More
                                </Link>
                            </article>
                        ))}
                </div>
            )}
        </React.Fragment>
    )
}

export default BlogThumb
